import { createRouter, createWebHashHistory } from 'vue-router'
// import Dashboard from "../views/Dashboard";

const routes = [
  { path: '/', redirect: { name: 'Dashboard' } },
  {
    path: '/dashboard', name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile', name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile'),
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiries', name: 'Inquiries',
    component: () => import(/* webpackChunkName: "about" */ '../views/DigitalCard/Inquiries'),
    meta: { requiresAuth: true },
  },
  {
    path: '/orders', name: 'Orders',
    component: () => import(/* webpackChunkName: "about" */ '../views/DigitalCard/Orders'),
    meta: { requiresAuth: true },
  },
  {
    path: '/cardBuilderDashboard', name: 'Card Builder Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/DigitalCard/Dashboard'),
    meta: { requiresAuth: true },
  },
  {
    path: '/cardBuilder', name: 'Card Builder',
    component: () => import(/* webpackChunkName: "about" */ '../views/DigitalCard/CardBuilder'),
    meta: { requiresAuth: true },
  },
  {
    path: '/business-detail', name: 'Business Detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessDetail.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/sign-up/:promoCode?', name: 'SignUp',
    component: () => import(/* webpackChunkName: "about" */ '../views/SignUp.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/byteBiz', name: 'ByteBiz',
    component: () => import(/* webpackChunkName: "about" */ '../views/ByteBiz'),
    meta: { requiresAuth: true },
  },
  {
    path: '/settings', name: 'Settings',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings'),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth) {
    if(localStorage.token && (localStorage.isBusinessDetailCompleted || to.path==='/profile')) next();
    else if(localStorage.token) router.replace({ path: '/profile'});
    else router.replace({path: '/login'})
  }else {
    if(localStorage.token) router.replace({ path: '/dashboard'});
    else next()
  }
})
