import moment from 'moment'

const Constants = {
    base_url: process.env.VUE_APP_BASE_API_URL,
    api_url: process.env.VUE_APP_API_URL,
    g_client_id: process.env.VUE_APP_GOOGLE_CLIENT,
    fb_app_id: process.env.VUE_APP_FACEBOOK_APP_ID,
    publish_app_domain: process.env.VUE_APP_PUBLISH_APP_DOMAIN,
    EmailPattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    YoutubePattern: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
    editorOptions: ($q) => [
        ['bold', 'italic', 'strike', 'underline'],
        [{
            label: $q.lang.editor.formatting, icon: $q.iconSet.editor.formatting, list: 'no-icons',
            options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
        }],
        [{
            label: $q.lang.editor.fontSize, icon: $q.iconSet.editor.fontSize, list: 'no-icons',
            options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
        }],
        [{
            label: $q.lang.editor.defaultFont, icon: $q.iconSet.editor.font, list: 'no-icons',
            options: ['default_font', 'arial', 'arial_black', 'comic_sans', 'courier_new', 'impact', 'times_new_roman', 'verdana']
        }],
        [{
            label: $q.lang.editor.align, icon: $q.iconSet.editor.align, fixedLabel: true,
            options: ['left', 'center', 'right', 'justify']
        }],
        ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
        ['undo', 'redo'],
        ['print', 'fullscreen'],
    ],
    fonts: {
        arial: 'Arial', arial_black: 'Arial Black', comic_sans: 'Comic Sans MS', courier_new: 'Courier New',
        impact: 'Impact', lucida_grande: 'Lucida Grande', times_new_roman: 'Times New Roman', verdana: 'Verdana'
    },
    DefaultThemeId: '62718a9a-4e96-433a-a250-d4b3133f5bb3',
    DefaultThemeCode: 'dc_theme_02',
    DefaultColorCode: 'green',
    DefaultHexCode: '#B0CB21',
    DefaultPromoCode: 'ByteCardFree',
    auth: {
        'GOOGLE': 1,
        'FACEBOOK': 2,
        'AJ': 3,
    },
    mapColToWidget: {
        products: 'Products', videos: 'Videos', certificates: 'Certificates', qrcodes: 'Payment Detail',
        branches: 'Contact Us', testimonials: 'Testimonial', themes: 'Themes', about_us: 'About Us',
        seo_details: 'SEO Details', customers: 'Business Details', business_detail: 'Business Details',
        social_media: 'Social Media'
    },
    DefaultWorkingHours: `<div style="font-family: Poppins, sans-serif; background-repeat: no-repeat; color: rgba(0, 0, 0, 0.87); font-size: 16px;"><table style="color: rgb(44, 62, 80); font-family: &quot;Avenir Next&quot;; font-size: 16px;"><tbody><tr><th style="text-align: left;">Sunday</th><td>: 09:00 AM - 01:00 PM</td></tr><tr><th style="text-align: left;">Monday</th><td>: 09:00 AM - 01:00 PM</td></tr><tr><th style="text-align: left;">Tuesday</th><td>: 09:00 AM - 01:00 PM</td></tr><tr><th style="text-align: left;">Wednesday&nbsp; &nbsp;&nbsp;</th><td>: 09:00 AM - 01:00 PM</td></tr><tr><th style="text-align: left;">Thursday</th><td>: 09:00 AM - 01:00 PM</td></tr><tr><th style="text-align: left;">Friday</th><td>: 09:00 AM - 01:00 PM</td></tr><tr><th style="text-align: left;">Saturday</th><td>: 09:00 AM - 01:00 PM</td></tr></tbody></table><br></div>`,
    ByteCardProductId: 'a9ec6071-ab8c-479e-b3d0-ee0cbfc68ae8'
};

export function getAddressString(a) {
    let str = '';
    if (a?.addressLine1) str = str + a.addressLine1 + ', ';
    if (a?.addressLine2) str = str + a.addressLine2 + ', ';
    if (a?.area) str = str + a.area + ', ';
    if (a?.city) str = str + a.city + ', ';
    if (a?.state) str = str + a.state;
    if (a?.pincode) str = str + ' - ' + a.pincode;
    if (a?.country) str = str + ', ' + a.country;
    return str;
}

export function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
}

export const dateOptions = {
    'Today': [moment().toDate(), moment().toDate()],
    'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
    'Last 1 day': [moment().subtract(1, 'days').toDate(), moment().toDate()],
    'Last 7 days': [moment().subtract(7, 'days').toDate(), moment().toDate()],
    'Last 30 days': [moment().subtract(30, 'days').toDate(), moment().toDate()],
    'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
    'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
}
export default Constants