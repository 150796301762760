<template>

  <template v-if="$route.path==='/login' || $route.name==='SignUp' || $route.path==='/'">
    <router-view @setUser="setUser"></router-view>
  </template>
  <template v-else>
    <q-layout view="hHh LpR fFf">
      <q-header class="bg-white text-grey-8" elevated>
        <q-toolbar>
          <q-btn dense flat round icon="menu" @click="onClickMenu"/>

          <q-toolbar-title>
            <q-avatar>
              <img src="https://cdn.quasar.dev/logo-v2/svg/logo-mono-white.svg" style="height: 30px">
            </q-avatar>
<!--            <span style="letter-spacing: 1.2px;margin-left: 10px">AJ Infosoft | {{$route.name}}</span>-->
          </q-toolbar-title>
          <div class="d-flex nd-info pr-3 pl-1 mt-2" style="align-items: center;" @click="redirectToProfile">
              <q-avatar v-if="user.path" @click.stop="openGallery({arr: [user]})">
                  <img :src="imgWpx(user.path, 200)" @error="user.path = null"/>
              </q-avatar>
              <div v-else :class="'nd-user-icon text-white bg-blue'">
                  <span style="font-size: 12px">{{user.name&&user.name.charAt(0)}}</span>
              </div>
              <div>
                  <span class="ml-3 d-block" style="font-size: 14px">{{user.name}}</span>
                  <span class="ml-3 d-block" style="font-size: 14px">{{user.email}}</span>
              </div>
          </div>
          <q-btn icon="logout" flat round title="Logout" @click="onLogout"/>
        </q-toolbar>
      </q-header>
      <q-drawer show-if-above v-model="drawer" side="left" class="bg-blue-grey-10 text-white" bordered :mini="miniVariant" :width="250">
        <q-scroll-area class="fit">
          <q-list>

            <template v-for="(m, index) in menuList" :key="index">
                <q-item clickable :active="$route.name == m.label" :to="m.path" active-class="text-blue" v-ripple v-if="m.path">
                    <q-item-section avatar><q-icon :name="m.icon" /></q-item-section>
                    <q-item-section>{{ m.label }}</q-item-section>
                </q-item>
                <q-expansion-item :icon="m.icon" :label="m.label" v-else expand-icon-class="text-white" :default-opened="true">
                    <q-list>
                        <template v-for="(submenu, idx) in m.submenus" :key="index">
                            <q-item clickable :active="$route.name == submenu.label" :to="submenu.path" active-class="text-blue" v-ripple>
                                <q-item-section avatar><q-icon :name="submenu.icon" /></q-item-section>
                                <q-item-section>{{ submenu.label }}</q-item-section>
                            </q-item>
                            <q-separator :key="'sb-sep' + idx"  v-if="submenu.separator" />
                        </template>
                    </q-list>
                </q-expansion-item>
                <q-separator :key="'sep' + index"  v-if="m.separator" />
            </template>


            <q-item clickable v-ripple style="position: absolute;bottom: 0" @click="drawer=!drawer">
              <q-item-section avatar><q-icon name="chevron_left" /></q-item-section>
            </q-item>

          </q-list>
        </q-scroll-area>
      </q-drawer>
      <q-page-container>
        <div class="q-pa-lg"> <router-view @openGallery="openGallery"/> </div>
      </q-page-container>
    </q-layout>
  </template>
    <lightbox id="g-app" :images="galleryImages" :current-image-name="currentImage" @on-lightbox-close="resetGallery"/>
</template>

<script>
  import { ref } from 'vue'
  import {useSubscription} from "@vue/apollo-composable";
  import {SUBSCRIBE_ORGANIZATION_PROFILE_BY_ID} from "./graphql/subscriptions/organizations";
  import Lightbox from 'vue-my-photos';
  import {gallery} from './mixins/gallery';

  export default {
    mixins: [gallery],
    components: {Lightbox},
    setup () {
      const drawer = ref(true);
      const miniVariant = ref(false);
      const menuList  = ref([
        { icon: 'dashboard', label: 'Dashboard', path:'/dashboard'},
        { icon: 'mdi-card-account-details-outline', label: 'ByteCard', submenus:[
                { icon: 'mdi-view-dashboard-variant', label: 'Dashboard', path:'/cardBuilderDashboard'},
                { icon: 'mdi-wrench-outline', label: 'ByteCard Builder', path:'/cardBuilder'},
                { icon: 'mdi-cog', label: 'Settings', path:'/settings'}]
        }
      ]);
      const onClickMenu = () => {
        if(!drawer.value){
          drawer.value = true;
          miniVariant.value = true
        } else miniVariant.value = !miniVariant.value
      };

      const user = ref({id:'', name:''});
      const {onResult} = useSubscription(SUBSCRIBE_ORGANIZATION_PROFILE_BY_ID, () => ({id: user.value.id}),
          () => ({enabled: !!user.value.id}));
      onResult(r => {
         Object.assign(user.value, r.data.organizations[0]);
         if(user.value.website) localStorage.setItem('subDomain', user.value.website);
      });

      return { drawer, miniVariant, menuList, onClickMenu, user}
    },
    methods:{
      setUser(user){
          this.user.id = user.id;
      },
      onLogout(){
        this.$q.dialog({
          title: 'Confirm', cancel: true, persistent: false,
          message: `Are you sure, You want to logout ?`,
        }).onOk(() => this.logout());
      },
      logout(){
        localStorage.clear();
        this.user.id = '';
        this.$router.push('/login');
      },
      redirectToProfile(){
        this.$router.push('/profile');
      }
    },
    created() {
        if(localStorage.user) this.setUser(JSON.parse(localStorage.user));
    }
  }


</script>

<style>
@import'./styles/spacing.css';
body{
  font-family: Avenir Next, Avenir, Helvetica, Arial, sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}


.aj-container {
  max-width: 1140px;
  margin: 0 auto;
}

.q-drawer--mini .q-mini-drawer-hide, .q-drawer--mini .q-expansion-item--expanded .q-expansion-item__content{
    display: unset !important;
}
.q-expansion-item__content{
    left: 4px;
}
.nd-info{
    transition: 0.3s;
}
.nd-info:hover{
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.11);
    border-radius: 50px;
}
.nd-user-icon{
    width: 35px;
    height: 35px;
    border-radius: 50px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    font-weight: 600;
}
.avatar{
  cursor: pointer;
}
.lightbox{
    z-index: 9999 !important;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-DemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/AvenirNext-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #969696;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5d5d5d;
}
</style>

<style lang="scss">
  //#app {
  //  font-family: Avenir Next, Avenir, Helvetica, Arial, sans-serif;;
  //  -webkit-font-smoothing: antialiased;
  //  -moz-osx-font-smoothing: grayscale;
  //  color: #2c3e50;
  //}
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-Bold.woff') format('woff');
  //  font-weight: bold;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-BoldItalic.woff') format('woff');
  //  font-weight: bold;
  //  font-style: italic;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-DemiBold.woff') format('woff');
  //  font-weight: 600;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-Heavy.woff') format('woff');
  //  font-weight: 900;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-MediumItalic.woff') format('woff');
  //  font-weight: 500;
  //  font-style: italic;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-Medium.woff') format('woff');
  //  font-weight: 500;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-HeavyItalic.woff') format('woff');
  //  font-weight: 900;
  //  font-style: italic;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-DemiBoldItalic.woff') format('woff');
  //  font-weight: 600;
  //  font-style: italic;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-Italic.woff') format('woff');
  //  font-weight: normal;
  //  font-style: italic;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-Regular.woff') format('woff');
  //  font-weight: normal;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-UltraLight.woff') format('woff');
  //  font-weight: 200;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Avenir Next';
  //  src: url('src/assets/fonts/AvenirNext-UltraLightItalic.woff') format('woff');
  //  font-weight: 200;
  //  font-style: italic;
  //  font-display: swap;
  //}


  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

</style>
