// Mixins
/**
 * gallery
 * @mixin
 *
 * Used to add common gallery functionality to components
 */

export const gallery = {
    data(){
        return{
            galleryImages: [],
            currentImage: ''
        }
    },
    methods:{
        openGallery({arr, index, skip}){
            this.galleryImages = arr.map(m => {return {
                name: skip ? m.path : this.imgWpx(m.path, 900)
            }})
            this.currentImage = this.galleryImages[index||0].name;
        },
        resetGallery(){
            this.currentImage = '';
        }
    }

};