import {createApp, h, provide} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';
import { ApolloClients } from "@vue/apollo-composable";
import {apolloClientA, apolloClientB} from "./vue-apollo";
import quasarUserOptions from './quasar-user-options';
import Constants from "./constants/Constants";

Object.defineProperty(Object, "copyExistingKeys", {
    value: (target, source) => {
        'use strict';
        if(target === null || target === undefined) {
            throw new TypeError('Cannot convert undefined or null to object');
        }
        Object.keys(target).forEach(key => {
            if (key in source) target[key] = source[key];
        });
    },
    writable: true,
    configurable: true,
});

const app = createApp({
    setup() { provide(ApolloClients, {default: apolloClientA, clientB: apolloClientB}) },
    render() { return h(App) }
});

app.mixin({
    data(){ return { responsive: false } },
    mounted() {
        this.responsive = window.innerWidth < 991;
    },
    methods: {
        imgWpx(path, size, type) {
            let sizes = path.split('/')[2]?.split('-') || [];
            if (!sizes.length || type === 'VIDEO') return Constants.base_url + path;
            if (!sizes.includes(String(size))) size = sizes.pop();
            return Constants.base_url + 'images/uploads/w-' + size + '/' + path.split('/').pop();
        },
        themeImage(themeCode, colorCode, screen, view){
            return `${Constants.base_url}themes/DC Theme 1/${themeCode}/${colorCode}_${screen}_${view}.jpg`
        }
    }
});

app.use(Quasar, {plugins:{Dialog, Notify}}, quasarUserOptions).use(store).use(router).mount('#app')
