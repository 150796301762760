import gql from "graphql-tag";

export const SUBSCRIBE_ORGANIZATION_BY_ID = gql`
subscription subscribeOrganizationById($id: uuid!){
  organizations(where: { id: {_eq: $id}}){
    id
    name
    mobile
    email
    website
    whatsapp
    business_detail
    address
    created_at
    updated_at
    password_updated_at
  }
}`

export const SUBSCRIBE_CARD_ORGANIZATION = gql`
subscription subscribeCardOrganization($id: uuid!){
  organizations(where: { id: {_eq: $id}}){
    id
    name
    logo
    mobile
    email
    website    
    whatsapp    
    business_detail
    address    
  }  
}`

export const SUBSCRIBE_CARD_THEME = gql`
subscription subscribeCardTheme($id: uuid!){  
  dc_cms(where: { customer_id: {_eq: $id}}){
    id
    theme
    social_media
  }
}`

export const SUBSCRIBE_ORGANIZATION_PROFILE_BY_ID = gql`
subscription subscribeOrganizationProfileById($id: uuid!){
  organizations(where: { id: {_eq: $id}}){
    id
    name    
    email
    path: logo
    website
  }
}`

export const SUBSCRIBE_ORGANIZATION_ORDERS_AND_INQUIRES_COUNT = gql`
subscription subscribeOrdersAndInquiriesCount($id: uuid!, $createdAt:timestamptz!, $startDate:timestamptz!, $endDate:timestamptz!){
  organizations(where: { id: {_eq: $id}}){
    id
    todayInquiriesCount: Inquiries_aggregate(where: {created_at: {_gte: $createdAt}}) { aggregate {count} }
    todayOrdersCount: Orders_aggregate(where: {created_at: {_gte: $createdAt}}) { aggregate {count} }
    Inquiries_aggregate (where:{_and: [{ created_at: {_gte: $startDate}},{ created_at: {_lte: $endDate}}]}) { aggregate {count} }
    Orders_aggregate (where:{_and: [{ created_at: {_gte: $startDate}},{ created_at: {_lte: $endDate}}]}) { aggregate {count} }
  }
}`

export const SUBSCRIBE_DC_PRODUCTS_BY_CUSTOMER_ID = gql`
subscription subscribeDcProducts($customerId: uuid!){
  products: dc_customer_products(where: { customer_id: {_eq: $customerId}}, order_by: {sequence: asc}){
    id
    name
    description
    price
    images
    is_active
    sequence    
  }
}`

export const SUBSCRIBE_DC_VIDEOS_BY_CUSTOMER_ID = gql`
subscription subscribeDcVideos($customerId: uuid!){
  videos: dc_customer_videos(where: { customer_id: {_eq: $customerId}}, order_by: {sequence: asc}){
    id
    name
    videoUrl 
    is_active
    sequence    
  }
}`

export const SUBSCRIBE_DC_CERTIFICATES_BY_CUSTOMER_ID = gql`
subscription subscribeDcCertificates($customerId: uuid!){
  certificates: dc_customer_certificates(where: { customer_id: {_eq: $customerId}}, order_by: {sequence: asc}){
    id
    name
    imageUrl 
    is_active
    sequence    
  }
}`

export const SUBSCRIBE_DC_TESTIMONIALS_BY_CUSTOMER_ID = gql`
subscription subscribeDcTestimonials($customerId: uuid!){
  testimonials: dc_customer_testimonials(where: { customer_id: {_eq: $customerId}}, order_by: {sequence: asc}){
    id
    person_name
    company_name
    description
    image_url 
    is_active
    sequence    
  }
}`

export const SUBSCRIBE_DC_QR_CODES_BY_CUSTOMER_ID = gql`
subscription subscribeDcQrcodes($customerId: uuid!){
  qrcodes: dc_customer_qrcodes(where: { customer_id: {_eq: $customerId}}, order_by: {sequence: asc}){
    id
    name
    image_url
    is_active
    sequence    
  }
}`

export const SUBSCRIBE_DC_BRANCHES_BY_CUSTOMER_ID = gql`
subscription subscribeDcBranches($customerId: uuid!){
  branches: dc_customer_branches(where: { customer_id: {_eq: $customerId}}, order_by: {sequence: asc}){
    id
    name
    iframe_code
    lat
    long
    phones
    emails
    address
    working_hours
    working_hours_title
    is_active
    is_default
    sequence    
  }
}`