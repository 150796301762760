import { HttpLink, split, ApolloClient, InMemoryCache } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from '@apollo/client/link/error';
import router from "./router";

const getToken = () => localStorage.getItem('token') || null;

const httpLinkA = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP,
});

const httpLinkB = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP_OPEN_API,
})

export const wsLinkA = new WebSocketLink({
    uri: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
    options: {
        reconnect: true,
        connectionParams() { return {
            headers: {
                token: getToken(),
                // 'X-Hasura-Admin-Secret': process.env.VUE_APP_HASURA_ADMIN_SECRET
            },
        }},
    },
});

export const wsLinkB = new WebSocketLink({
    uri: process.env.VUE_APP_GRAPHQL_WS_OPEN_API || 'ws://localhost:4000/graphql',
    options: {
        reconnect: true,
        connectionParams() { return {
            headers: {'X-Hasura-Admin-Secret': process.env.VUE_APP_HASURA_ADMIN_SECRET_OPEN_API},
        }},
    },
});

wsLinkA.subscriptionClient.on('connecting', () => {
    console.log('ws connecting');
});

wsLinkA.subscriptionClient.on('connected', () => {
    console.log('ws connected');
});

wsLinkA.subscriptionClient.on('reconnecting', () => {
    console.log('ws reconnecting');
});

wsLinkA.subscriptionClient.on('reconnected', () => {
    console.log('ws reconnected');
});

wsLinkA.subscriptionClient.on('disconnected', () => {
    console.log('ws disconnected');
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
            if(message === 'Invalid response from authorization hook' || message.includes('unauthorized')){
                console.log('token expired');
                localStorage.clear();
                router.replace({ path: '/login' });
            }else console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
    if (networkError) console.log(`[Network error]:`, networkError)
});

const authLinkA = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            token: getToken(),
            // 'X-Hasura-Admin-Secret': process.env.VUE_APP_HASURA_ADMIN_SECRET,
        },
    }
});

const authLinkB = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            'X-Hasura-Admin-Secret': process.env.VUE_APP_HASURA_ADMIN_SECRET_OPEN_API,
        },
    }
});

const linkA = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLinkA,
    httpLinkA,
);

const linkB = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLinkB,
    httpLinkB,
);

export const apolloClientA = new ApolloClient({
    link: authLinkA.concat(errorLink.concat(linkA)),
    cache: new InMemoryCache(),
    connectToDevTools: true
});

export const apolloClientB = new ApolloClient({
    link: authLinkB.concat(linkB),
    cache: new InMemoryCache(),
    connectToDevTools: true
});

